import React from 'react'
import { Element } from 'react-scroll'
import SEO from '../components/seo'

import PageWrapper from '../components/PageWrapper'

import Blogs from '../slices/Blogs'
import { Section, Title, Text } from '../components/Core'

const BlogPage = () => {
  return (
    <>
      <PageWrapper darkTheme={false}>
        <SEO title="Thankyou"/>
        
        <Section hero className='text-center' pb="0px">

            <Title>Thankyou for your submission!</Title>
            <Text variant="small" color="black" className='pt-5'>While you wait for us to get back to you, feel free to check out our blog.</Text>

        </Section>
        <Section>
          <Blogs hide={true}/>
        </Section>
      </PageWrapper>
    </>
  )
}
export default BlogPage
